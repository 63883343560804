import { useMemo } from 'react';
import { getFeatureConfig } from '@dx-ui/utilities-feature-toggles-configs';
import { SINGLE_ROOM_ONLY } from '../constants/featureToggles';
import { useFeatureTogglesQuery } from '../gql/queries';

export function useCanOnlyBookSingleRoom(brandCode: string) {
  const { data } = useFeatureTogglesQuery({ names: SINGLE_ROOM_ONLY });
  const singleRoomOnlyBrandsConfig = getFeatureConfig<{ brandCodes: string[] }>({
    featureToggleData: data,
    name: SINGLE_ROOM_ONLY,
  });

  return useMemo(
    () => singleRoomOnlyBrandsConfig?.brandCodes.includes(brandCode) || false,
    [brandCode, singleRoomOnlyBrandsConfig?.brandCodes]
  );
}
