import type { PlacesAutocompleteSuggestion } from '@dx-ui/framework-places-autocomplete';
import { useEffect, useState } from 'react';
type IExpire<T> = {
  expireDate: number;
  instance: T;
};

/**
 * @param ttl milliseconds
 */
export const saveToSession = <T>(object: T, key: string, ttl: number) => {
  const env: IExpire<T> = {
    instance: object,
    expireDate: new Date().getTime() + ttl,
  };
  window.sessionStorage.setItem(key, JSON.stringify(env));
};

const useFromSession = <T>(key: string) => {
  const [env, setEnv] = useState<{ expireDate: Date; instance: T } | null>(null);

  useEffect(() => {
    const env: { expireDate: Date; instance: T } | null = window?.sessionStorage?.getItem(key)
      ? JSON.parse(window?.sessionStorage?.getItem(key) as string)
      : null;

    setEnv(env);
  }, [key]);

  if (!env) return null;

  const ttl = new Date(env.expireDate).getTime() - new Date().getTime();

  if (ttl <= 0) {
    window?.sessionStorage?.removeItem(key);
    return null;
  }
  return env.instance;
};

export const RECENT_SEARCH = {
  SESSION_KEY: 'RecentSearches',
  SELECTED_SUGGESTION_SESSION_KEY: 'SelectedSuggestion',
  REFRESH_TTL: 3600,
  REFRESH_TTL_2WEEK: 604800 * 2,
  DISPLAY_COUNT: 5,
};

const useLatestRecentSearches = ({
  suggestion,
  displayCount = RECENT_SEARCH.DISPLAY_COUNT,
}: { suggestion?: PlacesAutocompleteSuggestion; displayCount?: number } = {}) => {
  const selectedSuggestion = useFromSession<PlacesAutocompleteSuggestion>(
    RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY
  );
  const recentSearches = useRecentSearches() ?? [];

  //get selected suggestion
  const newRecentSearchOption = suggestion ?? selectedSuggestion;
  if (newRecentSearchOption) {
    const recentSearchFound = recentSearches
      .map((recentSearch) => recentSearch.description)
      .includes(newRecentSearchOption.description);
    if (!recentSearchFound) {
      // adjust recent search list
      if (recentSearches.length === displayCount) recentSearches.pop();
      //add selected options to recent search list
      if (newRecentSearchOption) recentSearches.unshift({ ...newRecentSearchOption });
    }
    //reset selected suggestion
    saveToSession(
      null,
      RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY,
      RECENT_SEARCH.REFRESH_TTL * 1000
    );
  }

  return recentSearches;
};

export const useSaveRecentSearchesToSession = () => {
  const recentSearches = useLatestRecentSearches({});

  return () => {
    if (recentSearches) {
      saveToSession(
        recentSearches,
        RECENT_SEARCH.SESSION_KEY,
        RECENT_SEARCH.REFRESH_TTL_2WEEK * 1000
      );
    }
  };
};

export const saveSelectedSuggestToSession = (suggestion: PlacesAutocompleteSuggestion) => {
  if (suggestion) {
    saveToSession(
      suggestion,
      RECENT_SEARCH.SELECTED_SUGGESTION_SESSION_KEY,
      RECENT_SEARCH.REFRESH_TTL * 1000
    );
  }
};

export const useRecentSearches = () => {
  const recentSearchOptions = useFromSession(
    RECENT_SEARCH.SESSION_KEY
  ) as PlacesAutocompleteSuggestion[];
  return recentSearchOptions;
};
