import { useMemo, useEffect } from 'react';
import type { UseFormProps } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { usePreviousValue } from '@dx-ui/utilities-use-previous-value';
import type { FormDataValues, FormDefaultValues } from './shop-form';
import { generateSanitizedDefaultValues } from './shop-form.utils';
import isEqual from 'lodash/isEqual';
import { useQuery, useQueryClient } from '@tanstack/react-query';

type SearchForProviderProps = React.PropsWithChildren<
  Omit<UseFormProps<FormDataValues>, 'defaultValues'> & { defaultValues?: FormDefaultValues }
>;

export function ShopFormProvider({ children, ...formProps }: SearchForProviderProps) {
  const defaultValuesWithDefaults = useMemo(
    () => generateSanitizedDefaultValues(formProps.defaultValues),
    [formProps.defaultValues]
  );
  const previousDefaultValues = usePreviousValue(defaultValuesWithDefaults);
  const methods = useForm<FormDataValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    ...formProps,
    defaultValues: defaultValuesWithDefaults,
  });
  const reset = methods.reset;
  const { defaultValues, setShopFormDefaultValues } = useShopFormDefaultValues();

  useEffect(() => {
    if (!isEqual(defaultValues, defaultValuesWithDefaults)) {
      setShopFormDefaultValues(defaultValuesWithDefaults);
    }
    if (!isEqual(previousDefaultValues, defaultValuesWithDefaults)) {
      reset(defaultValuesWithDefaults);
    }
  }, [
    defaultValues,
    defaultValuesWithDefaults,
    reset,
    previousDefaultValues,
    setShopFormDefaultValues,
  ]);

  return <FormProvider {...methods}>{children}</FormProvider>;
}

export function useShopFormDefaultValues() {
  const queryClient = useQueryClient();
  const queryKey = ['oscShopFormDefaultValues'];
  const { data: defaultValues } = useQuery<FormDataValues>({ queryKey, enabled: false });
  const setShopFormDefaultValues = (defaultValues: FormDefaultValues) => {
    queryClient.setQueryData(queryKey, defaultValues);
  };
  return {
    defaultValues,
    setShopFormDefaultValues,
  };
}
