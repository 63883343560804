import type * as React from 'react';
import { useTranslation } from 'next-i18next';
import { Dialog } from '@dx-ui/osc-dialog';
import set from 'lodash/set';

export type SpeedbumpProps = {
  /**
   * displays the speeedbump or not
   */
  isShowing: boolean;
  /**
   * handler to close the speedbump
   */
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * handler used to proceed if chosen
   */
  onContinue: React.MouseEventHandler<HTMLButtonElement>;
};

/**
 * Used to display a speedbump when transitioning to a non translated page
 */
export const Speedbump: React.FC<React.PropsWithChildren<SpeedbumpProps>> = ({
  isShowing,
  onClose: onCloseProp,
  onContinue: onContinueProp,
}) => {
  const [t] = useTranslation('osc-speedbump');
  const onContinue: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (window?.digitalData && window?._satellite) {
      set(window.digitalData, 'click.clickID', 'speedbump_continue');
      window._satellite?.track?.('global_click');
    }
    onContinueProp?.(e);
  };
  const onClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (window?.digitalData && window?._satellite) {
      set(window.digitalData, 'click.clickID', 'speedbump_return');
      window._satellite?.track?.('global_click');
    }
    onCloseProp?.(e);
  };

  return (
    <Dialog ariaLabel="why" isOpen={isShowing} onDismiss={onClose} size="sm">
      <div data-testid="speedbumpDialog" className="mt-3">
        <h2 className="mb-4 text-center text-xl font-bold">{t('title')}</h2>
        <div className="mb-2">
          <button type="button" className="btn btn-primary btn-xl w-full" onClick={onContinue}>
            {t('continue')}
          </button>
        </div>
        <div>
          <button type="button" className="btn btn-primary-outline btn-xl w-full" onClick={onClose}>
            {t('back')}
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export default Speedbump;
