import { ActionDialog } from '@dx-ui/osc-dialog';
import { Link } from '@dx-ui/osc-link';
import * as React from 'react';
import { useFieldArray, useWatch } from 'react-hook-form';
import type { FormDefaultValues } from './shop-form';
import { useShopFormContext } from './use-shop-form-context';
import { useTranslation } from 'next-i18next';
import type { Person, BrandCode } from '@dx-ui/framework-uri-builder';
import { searchUriBuilder } from '@dx-ui/framework-uri-builder';
import SearchButton from './shop-form.button';
import omit from 'lodash/omit';
import type { Rooms as RoomsType, ShopFormRoom } from '@dx-ui/osc-rooms';
import { Rooms, newRoom } from '@dx-ui/osc-rooms';
import cloneDeep from 'lodash/cloneDeep';
import { useCanOnlyBookSingleRoom } from './hooks/use-can-only-book-single-room';

type ShopFormRooms = {
  'data-testid'?: string;
  /**
   * if `true` the group link will not be shown
   */
  hideGroupLink?: boolean;
  open?: boolean;
  tenPlusLinkProps?: React.ComponentProps<typeof Link>;
  /** Callback invoked on confirm/done */
  onConfirm?: () => void;
  /** Callback invoked on modal open */
  onOpen?: () => void;
  /** Callback invoked on cancel/dismiss */
  onDismiss?: () => void;
} & RoomsType;

/**
 * Use the `<ShopFormRooms/>` component inside of the ShopForm in order to provide the OSC experience
 * for noting the number of rooms and their occupancy.
 */
export const ShopFormRooms: React.FC<React.PropsWithChildren<ShopFormRooms>> = ({
  hideGroupLink,
  onConfirm,
  open: openProp,
  tenPlusLinkProps,
  onOpen,
  onDismiss,
  ...rest
}) => {
  const { t } = useTranslation(['osc-rooms', 'osc-shop-form']);
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const {
    formState: { errors },
    getValues,
    reset,
  } = useShopFormContext();
  const { append } = useFieldArray({ name: 'rooms' });
  const hasError = Boolean(errors.rooms);
  const roomsValue = useWatch({ name: 'rooms' });
  const numRooms = roomsValue?.length || 0;

  const numGuests = (roomsValue || []).reduce(
    (count: number, { adults, children }: { adults: number; children: Person[] }) =>
      count + adults + children.length,
    0
  );
  const [initialValues, setInitialValues] = React.useState<ShopFormRoom[]>([
    {
      adults: 1,
      children: [],
    },
  ]);

  React.useEffect(() => {
    if (roomsValue && roomsValue.length === 0) {
      append({ ...newRoom });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomsValue]);

  React.useEffect(() => {
    if (openProp) {
      setInitialValues(cloneDeep(roomsValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openProp]);

  const openModal = () => {
    setInitialValues(cloneDeep(roomsValue));
    setOpen(true);
    onOpen?.();
  };

  const confirmAndCloseModal = () => {
    setOpen(false);
    onConfirm?.();
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };
  const dismissAndResetToOriginalFormRooms = () => {
    if (initialValues) {
      reset({ ...getValues(), rooms: [...initialValues] }, { keepDefaultValues: true });
    }
    setOpen(false);
    onDismiss?.();
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  //grab these values from shop form context in order for use in uri generator
  const brandCode = useWatch<FormDefaultValues, 'brandCode'>({ name: 'brandCode' });
  const arrivalDate = useWatch<FormDefaultValues, 'dates.arrivalDate'>({
    name: 'dates.arrivalDate',
  });
  const departureDate = useWatch<FormDefaultValues, 'dates.departureDate'>({
    name: 'dates.departureDate',
  });
  const placeId = useWatch<FormDefaultValues, 'placeId'>({ name: 'placeId' });
  const query = useWatch<FormDefaultValues, 'query'>({ name: 'query' });
  const attendeeCount = useWatch<FormDefaultValues, 'attendee_count'>({ name: 'attendee_count' });
  const canOnlyBookSingleRoom = useCanOnlyBookSingleRoom(brandCode || '');

  const tenPlusUrl = searchUriBuilder({
    brandCode: brandCode as keyof BrandCode,
    locale: 'en',
    urlParams: {
      dates: {
        arrivalDate,
        departureDate,
      },
      numAttendees: attendeeCount,
      placeId,
      query,
      numRooms: 10,
    },
    relative: false,
  });

  const label = `${t('occupancy.numRooms', { count: numRooms })}, ${t('occupancy.numGuests', {
    count: numGuests,
  })}`;
  const ariaLabel = `${t('occupancy.numRooms', { count: numRooms })}, ${t('occupancy.numGuests', {
    count: numGuests,
  })}. ${t('occupancy.buttonLabel')} ${
    hasError ? t('osc-shop-form:errorHeader', { count: 1 }) : ''
  }`;

  return (
    <SearchButton
      product="search-rooms-button"
      ref={buttonRef}
      onClick={openModal}
      hasError={hasError}
      label={label}
      ariaLabel={ariaLabel}
      data-testid="search-rooms-button"
    >
      <ActionDialog
        className="flex flex-col overflow-auto"
        contentClassName="h-auto"
        ariaLabel={t('occupancy.title')}
        title={t('occupancy.title')}
        isOpen={openProp || open}
        onDismiss={dismissAndResetToOriginalFormRooms}
        onConfirm={confirmAndCloseModal}
        size="md"
        buttonOptions={{
          confirm: {
            disabled: hasError,
          },
        }}
      >
        <Rooms {...omit(rest, 'data-testid')} canOnlyBookSingleRoom={canOnlyBookSingleRoom} />
        {hideGroupLink ? null : (
          <div className="border-border border-t border-solid pb-2.5 pt-2">
            <Link
              url={tenPlusUrl}
              className="text-primary hover:text-primary-alt mb-2 text-sm"
              {...tenPlusLinkProps}
            >
              {t('occupancy.bookEvent')}
            </Link>
          </div>
        )}
      </ActionDialog>
    </SearchButton>
  );
};

export default ShopFormRooms;
