import Icon from '@dx-ui/osc-icon';
import { useIsClient } from 'usehooks-ts';
import { isKanji, useLocale, useDateFormat } from '@dx-ui/utilities-dates';
import cx from 'classnames';
import type * as React from 'react';

type DatePickerDatesButton = {
  children: React.ReactNode;
  className?: HTMLDivElement['className'];
  day: Date;
  language: string;
};

const DatePickerDatesButton: React.FC<React.PropsWithChildren<DatePickerDatesButton>> = ({
  className,
  children,
  day,
  language,
}) => {
  const locale = useLocale({ language });
  const kanji = isKanji(locale) as boolean;
  const options = kanji ? { weekday: 'long' as Intl.DateTimeFormatOptions['weekday'] } : {};
  const dateFormatParts = useDateFormat({ date: day, locale, options });
  const isClient = useIsClient();

  if (!day) {
    return (
      <div className="flex w-36 items-center justify-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0">
        <Icon name="calendar" size="xl" variant="solid" className="text-primary" />
        {children ? (
          <div data-osc-product="date-picker-buttons" className="ms-2 text-sm leading-tight">
            {children}
          </div>
        ) : null}
      </div>
    );
  }

  if (!isClient) {
    return (
      <div className="flex w-36 items-center justify-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0">
        <div className={cx('bg-bg-alt w-20 rounded', kanji ? 'h-12' : 'h-11')} />
      </div>
    );
  }

  return kanji ? (
    <div
      aria-hidden
      className={cx(
        'flex h-12 items-center whitespace-nowrap sm:w-auto sm:ps-2 sm:first-of-type:ps-0',
        className
      )}
    >
      <div className="row-span-1 row-start-2">
        <span className="block uppercase tabular-nums leading-none">
          {dateFormatParts.month.numeric}
          {dateFormatParts.month.text}
        </span>
      </div>
      <div className="row-span-4 row-start-1">
        <span className="text-primary pr-1 text-4xl font-bold tabular-nums leading-none md:text-3xl lg:text-4xl">
          {dateFormatParts.day.numeric}
          <span className="uppercase tabular-nums">{dateFormatParts.day.text}</span>
        </span>
        <span className="block uppercase tabular-nums leading-none">{dateFormatParts.weekday}</span>
      </div>
    </div>
  ) : (
    <div
      aria-hidden
      className={cx(
        'flex w-32 h-11 items-end justify-center sm:w-auto sm:ps-2 sm:first-of-type:ps-0',
        className
      )}
    >
      <span className="text-primary brand-ey:font-normal brand-lx:font-serif brand-lx:text-brand brand-ou:font-headline brand-ou:font-normal pe-1 text-5xl font-bold tabular-nums leading-[0.9]">
        {dateFormatParts.day.numeric}
      </span>
      <div className="flex flex-col">
        <span className="brand-ey:font-normal brand-lx:font-semibold brand-ou:text-primary block font-bold uppercase tabular-nums leading-none">
          {dateFormatParts.month.text}
        </span>
        <span className="brand-ou:text-primary block uppercase tabular-nums">
          {dateFormatParts.weekday}
        </span>
      </div>
    </div>
  );
};

export { DatePickerDatesButton };
export default DatePickerDatesButton;
