import type { FormDataValues, FormDefaultValues, TargetOHWPage } from './shop-form';
import type {
  BrandCode,
  DreamUrlParams,
  SortByValues,
  TripAdvisorRatings,
  MapLanguage,
  Path,
} from '@dx-ui/framework-uri-builder';
import { searchUriBuilder, bookUriBuilder, dreamUriBuilder } from '@dx-ui/framework-uri-builder';
import { DEFAULT_FORM_VALUES } from './shop-form.constants';
import merge from 'lodash/merge';
import { differenceInDays, startOfDay } from 'date-fns';
import type { FeatureTogglesQuery, GeocodeQuery } from './gql/types';
import type { SpecialRates } from '@dx-ui/osc-special-rates';
import {
  isTMTPRateSelected,
  isHGVMax,
  isHGVMaxRateSelected,
  isOwner,
  isTeamMember,
  isFriendsAndFamily,
  isTMTPProgramMember,
} from '@dx-ui/osc-special-rates';
import type { QueryClient } from '@tanstack/react-query';
import {
  getSpeedBumpFeatureConfig,
  GetSpeedBumpFeatureConfigsDocument,
  type GetSpeedBumpFeatureConfigsQuery,
} from '@dx-ui/osc-speedbump';
import { FeatureTogglesDocument, serverSideFeatureTogglesQuery } from './gql/queries';
import {
  GetTranslateAutocompleteConfigDocument,
  serverSideGetTranslateAutocompleteConfigQuery,
} from '@dx-ui/osc-location';
import { NHCSEARCH_4807, SINGLE_ROOM_ONLY } from './constants/featureToggles';

export const RatePlanCodes = {
  GOVERNMENT_MILITARY: 'GDSGOV',
  AARP: 'HCARP',
  SENIOR: 'GDSSNR',
  AAA: 'HCAAA',
  USE_POINTS: 'HCSMR',
} as const;

export const getSearchPath = (
  data: Partial<FormDataValues>,
  targetOHWPage: Omit<TargetOHWPage, 'book' | 'ten-plus-book'>
): Path => {
  if (isTMTPRateSelected(data?.specialRates) && isTMTPProgramMember(data.hhonors)) {
    if (targetOHWPage === 'search') {
      return 'go-hilton';
    } else if (targetOHWPage === 'search-zero') {
      return 'go-hilton-find-hotels';
    }
  } else if (isHGVMax(data.hhonors) && isHGVMaxRateSelected(data?.specialRates)) {
    if (targetOHWPage === 'search') {
      return 'hgv-max';
    } else if (isHGVMax(data.hhonors) && targetOHWPage === 'search-zero') {
      return 'hgv-max-find-hotels';
    }
  } else if (targetOHWPage === 'search') {
    return 'search';
  } else if (targetOHWPage === 'ten-plus-search') {
    return 'group-search';
  }
  return 'find-hotels';
};

/** This must be updated along with the additionalQSParameterKeys array below */
export type AdditionalQSParameters = {
  adjoiningRoomStay?: boolean;
  availableHotelsOnly?: boolean;
  brandCode?: string;
  content?: string;
  displayCurrency?: string;
  fromId?: string;
  f_attributeIds?: string[];
  f_amenityIds?: string[];
  f_brandCodes?: string[];
  f_price?: [number, number];
  f_tripAdvisorRatings?: TripAdvisorRatings;
  maxPoints?: number;
  requestedRatesOnly?: boolean;
  sortBy?: SortByValues;
  specPlan?: string[];
  specialRatesTokens?: string;
  redeemPts?: boolean;
  token?: string[];
  cid?: string;
};

/** This must be updated along with the AdditionalQSParameters type above */
export const additionalQSParameterKeys: Array<keyof AdditionalQSParameters> = [
  'adjoiningRoomStay',
  'availableHotelsOnly',
  'brandCode',
  'cid',
  'content',
  'displayCurrency',
  'f_amenityIds',
  'f_attributeIds',
  'f_brandCodes',
  'f_price',
  'f_tripAdvisorRatings',
  'fromId',
  'maxPoints',
  'redeemPts',
  'requestedRatesOnly',
  'sortBy',
  'specPlan',
  'specialRatesTokens',
  'token',
];

export function isAdditionalQsParameterKey(
  someString: string
): someString is keyof AdditionalQSParameters {
  return Boolean(additionalQSParameterKeys.find((key) => someString === key));
}

const getNumRooms = (numRooms?: number | null) => {
  return numRooms == null || isNaN(numRooms) ? null : numRooms;
};

export const generateShopFormUrl = async ({
  additionalQSParameters,
  formData,
  locale,
  targetOHWPage,
  enableDatesFlexDreamRouting = false,
  geocodeData,
  client,
}: {
  additionalQSParameters?: AdditionalQSParameters;
  formData: Partial<FormDataValues>;
  locale: string;
  targetOHWPage: TargetOHWPage;
  enableDatesFlexDreamRouting?: boolean;
  geocodeData?: GeocodeQuery;
  client?: QueryClient;
}) => {
  const token = additionalQSParameters?.token;
  /** If enableDatesFlexDreamRouting enabled, route user to locations result page
   * if not using special rates beside points,
   *  date range is within 30 days of today and language is English.
   * If geocode fails for dream routing, fallback to default routing.
   */
  const flexDatesDreamRouting = isflexDatesDreamRouting({
    data: formData,
    target: targetOHWPage,
    enableDreamRouting: enableDatesFlexDreamRouting,
    geocodeData,
  });

  /** OHW Search URL generation */

  //if flexdates dream redirect enabled and conditions met, return dream URL
  if (flexDatesDreamRouting) {
    return await generateFlexDatesDreamRedirectURL({
      formData,
      language: locale,
      additionalQSParameters,
      geocodeData,
      client,
    });
  }
  if (
    targetOHWPage === 'search' ||
    targetOHWPage === 'search-zero' ||
    targetOHWPage === 'ten-plus-search'
  ) {
    const brandCodeParam = additionalQSParameters?.brandCode as keyof BrandCode;
    return searchUriBuilder({
      brandCode: brandCodeParam ?? (formData.brandCode as keyof BrandCode),
      locale: locale as MapLanguage,
      path: getSearchPath(formData, targetOHWPage),
      relative: true,
      urlParams: {
        adjoiningRoomStay: additionalQSParameters?.adjoiningRoomStay,
        content: additionalQSParameters?.content,
        dates: {
          arrivalDate: formData?.dates?.arrivalDate,
          departureDate: formData?.dates?.departureDate,
          flexibleDates: formData?.dates?.datesFlex,
        },
        displayCurrency: additionalQSParameters?.displayCurrency,
        placeId: formData?.placeId,
        query: formData?.query,
        maxPoints: formData?.specialRates?.redeemPts ? additionalQSParameters?.maxPoints : null,
        numAttendees: formData?.attendee_count,
        availableHotelsOnly: additionalQSParameters?.availableHotelsOnly,
        f_amenityIds: additionalQSParameters?.f_amenityIds,
        f_attributeIds: additionalQSParameters?.f_attributeIds,
        f_brandCodes: additionalQSParameters?.f_brandCodes,
        f_price: additionalQSParameters?.f_price,
        f_tripAdvisorRatings: additionalQSParameters?.f_tripAdvisorRatings,
        sortBy: additionalQSParameters?.sortBy,
        //if on a 10+ search uri or numRooms is provided with a value less than 10, consume numRooms. Otherwise consume length of rooms array from rooms modal sub component
        numRooms:
          targetOHWPage === 'ten-plus-search' || (formData?.numRooms && formData?.numRooms < 10)
            ? formData?.numRooms
            : formData?.rooms?.length,
        rates: {
          aarp: formData?.specialRates?.aarpRate,
          corporateAccount: formData?.specialRates?.corporateCode,
          employeeRate: formData?.specialRates?.employeeRate,
          friendsAndFamilyRate: formData?.specialRates?.friendsAndFamilyRate,
          fromId: additionalQSParameters?.fromId,
          government: formData?.specialRates?.governmentOrMilitaryRate,
          groupCode: formData?.specialRates?.groupCode,
          offerId: formData?.specialRates?.useOfferId ? formData?.specialRates?.offerId?.id : null,
          ownerHGVRate: formData?.specialRates?.ownerHGVRate,
          ownerVIPRate: formData?.specialRates?.ownerVIPRate,
          pnd: formData?.specialRates?.usePnd ? formData?.specialRates?.pnd?.pnd : null,
          promoCode: formData?.specialRates?.promoCode,
          requestedRatesOnly: additionalQSParameters?.requestedRatesOnly,
          senior: formData?.specialRates?.seniorRate,
          smbRate: formData?.specialRates?.smbRate,
          travelAgents: formData?.specialRates?.travelAgentRate,
          tripleA: formData?.specialRates?.aaaRate,
          spec_plan: additionalQSParameters?.specPlan?.join(','),
          //TODO PAUL. THIS LOGIC MAKES NO SENSE. IF CUSTOMER SAYS USEPOINTS IS FALSE BUT I HAVE A TOKEN I IGNORE IT?
          usePoints:
            token?.includes(RatePlanCodes.USE_POINTS) ||
            formData?.specialRates?.redeemPts ||
            additionalQSParameters?.redeemPts,
        },
        rooms: formData?.rooms,
        sessionToken: formData?.sessionToken,
        specialRateTokens: additionalQSParameters?.specialRatesTokens?.split(','),
        cid: additionalQSParameters?.cid,
      },
    });
  }

  //TODO talk to kevin
  /** OHW Book URL Generation */
  return bookUriBuilder({
    locale: locale as MapLanguage,
    page: formData?.dates?.datesFlex ? 'flexibledates' : 'rooms',
    relative: true,
    urlParams: {
      additionalQS: {
        displayCurrency: additionalQSParameters?.displayCurrency,
        requestedRatesOnly: additionalQSParameters?.requestedRatesOnly,
        spec_plan: additionalQSParameters?.specPlan?.join(','),
        specialRateTokens: additionalQSParameters?.specialRatesTokens,
        srpName: additionalQSParameters?.specialRatesTokens,
      },
      adjoiningRoomStay: additionalQSParameters?.adjoiningRoomStay,
      ctyhocn: formData.query || '',
      dates: {
        arrivalDate: formData?.dates?.arrivalDate,
        departureDate: formData?.dates?.departureDate,
        flexibleDates: formData?.dates?.datesFlex,
      },
      rates: {
        aaaRate: formData?.specialRates?.aaaRate,
        aarpRate: formData?.specialRates?.aarpRate,
        corporateCode: formData?.specialRates?.corporateCode,
        employeeRate: formData?.specialRates?.employeeRate,
        friendsAndFamilyRate: formData?.specialRates?.friendsAndFamilyRate,
        fromId: additionalQSParameters?.fromId,
        governmentRate: formData?.specialRates?.governmentOrMilitaryRate,
        groupCode: formData?.specialRates?.groupCode,
        offerId: formData?.specialRates?.offerId?.id,
        ownerHGVRate: formData?.specialRates?.ownerHGVRate,
        ownerVIPRate: formData?.specialRates?.ownerVIPRate,
        pnd: formData?.specialRates?.pnd?.pnd,
        promotionCode: formData?.specialRates?.promoCode,
        redeemPts: formData?.specialRates?.redeemPts,
        seniorRate: formData?.specialRates?.seniorRate,
        smbRate: formData?.specialRates?.smbRate,
        travelAgent: formData?.specialRates?.travelAgentRate,
        travelAgentId: formData?.specialRates?.travelAgentId,
      },
      rooms: formData?.rooms,
      numRooms: getNumRooms(formData.numRooms),
      numAttendees: formData?.numAttendees,
    },
  });
};

export const generateSanitizedDefaultValues = (defaultValues?: FormDefaultValues) => {
  const initialValues: FormDataValues = merge({}, DEFAULT_FORM_VALUES, defaultValues);

  if (initialValues?.dates?.arrivalDate) {
    initialValues.dates.arrivalDate = startOfDay(initialValues.dates.arrivalDate);
  }
  if (initialValues?.dates?.departureDate) {
    initialValues.dates.departureDate = startOfDay(initialValues.dates.departureDate);
  }

  //clear HGV rate is user not eligible
  if (!isHGVMax(initialValues?.hhonors)) {
    initialValues['specialRates']['ownerHGVRate'] = false;
  }

  //clear tmtp rates if user is not eligible
  if (!isOwner(initialValues?.hhonors)) {
    if (isTeamMember(initialValues?.hhonors)) {
      initialValues['specialRates']['ownerVIPRate'] = false;
    } else if (isFriendsAndFamily(initialValues?.hhonors)) {
      initialValues['specialRates']['ownerVIPRate'] = false;
      initialValues['specialRates']['employeeRate'] = false;
    } else {
      initialValues['specialRates']['ownerVIPRate'] = false;
      initialValues['specialRates']['employeeRate'] = false;
      initialValues['specialRates']['friendsAndFamilyRate'] = false;
    }
  }

  return initialValues;
};

export const isflexDatesDreamRouting = ({
  data,
  target,
  enableDreamRouting,
  geocodeData,
}: {
  data: Partial<FormDataValues>;
  target: string;
  enableDreamRouting?: boolean;
  geocodeData?: GeocodeQuery;
}) => {
  const noSpecialRatesOrOnlyUsePointsApplied = (specialRates?: Partial<SpecialRates>) => {
    //no special rate applied
    if ((specialRates && Object.values(specialRates).filter(Boolean).length === 0) || !specialRates)
      return true;
    //or only one special rate applied and that rate is usePoints
    if (
      specialRates &&
      Object.values(specialRates).filter(Boolean).length < 2 &&
      specialRates.redeemPts
    )
      return true;
    return false;
  };
  const isDateLess = !(data?.dates?.arrivalDate && data?.dates?.departureDate);
  return (
    //flexDates dream routing opt-in
    enableDreamRouting &&
    /*geocode data returned for routing to dream flow 
    or search routing passed from dream page to route to search flow*/
    !!geocodeData &&
    !!geocodeData?.geocode?.match?.placeUri &&
    (target === 'search' || target === 'search-zero') &&
    noSpecialRatesOrOnlyUsePointsApplied(data?.specialRates) &&
    //searched dates must be within 30 days of today or dateless
    (isDateLess ||
      (!!data?.dates?.arrivalDate &&
        data?.dates?.datesFlex &&
        differenceInDays(data?.dates?.arrivalDate, new Date()) <= 30))
  );
};
export const generateFlexDatesDreamRedirectURL = async ({
  formData,
  language,
  additionalQSParameters,
  geocodeData,
  client,
}: {
  formData?: Partial<FormDataValues>;
  language: string;
  additionalQSParameters?: AdditionalQSParameters;
  geocodeData?: GeocodeQuery;
  client?: QueryClient;
}) => {
  let shouldAddLocationCoordParams = false;
  try {
    if (client) {
      const { featureToggles } = await serverSideFeatureTogglesQuery(client, {
        names: NHCSEARCH_4807,
      });
      shouldAddLocationCoordParams =
        featureToggles.find(({ name }) => NHCSEARCH_4807 === name)?.enabled || false;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const locationCoordParams = {
    placeId: geocodeData?.geocode?.match?.id,
    location: geocodeData?.geocode?.match?.geometry?.location,
    bounds: geocodeData?.geocode?.match?.geometry?.bounds,
    geocodeType: geocodeData?.geocode?.match?.type,
    query: formData?.query,
  };
  const urlParams: DreamUrlParams = {
    displayCurrency: additionalQSParameters?.displayCurrency,
    adjoiningRoomStay: additionalQSParameters?.adjoiningRoomStay,
    sortBy: additionalQSParameters?.sortBy,
    f_amenityIds: additionalQSParameters?.f_amenityIds,
    f_brandCodes: additionalQSParameters?.f_brandCodes,
    f_price: additionalQSParameters?.f_price,
    f_tripAdvisorRatings: additionalQSParameters?.f_tripAdvisorRatings,
    redeemPts: formData?.specialRates?.redeemPts || additionalQSParameters?.redeemPts,
    placeUri: geocodeData?.geocode?.match?.placeUri,
    cid: additionalQSParameters?.cid,
    content: additionalQSParameters?.content,
    ...(shouldAddLocationCoordParams && locationCoordParams),
  };
  const brandCodeParam = additionalQSParameters?.brandCode as keyof BrandCode;
  const dreamUrlString = dreamUriBuilder({
    locale: language,
    brandCode: brandCodeParam ?? (formData?.brandCode as keyof BrandCode),
    urlParams,
    relative: true,
  });
  return dreamUrlString;
};

export const SHOP_FORM_ORIGINAL_OP_NAMES = [
  GetSpeedBumpFeatureConfigsDocument.originalOpName,
  GetTranslateAutocompleteConfigDocument.originalOpName,
  FeatureTogglesDocument.originalOpName,
];
export const getServerSideShopFormData: (
  queryClient: QueryClient,
  options?: { getTranslateAutocomplete?: boolean }
) => Promise<[void | GetSpeedBumpFeatureConfigsQuery, void | FeatureTogglesQuery]> = (
  queryClient,
  options = {}
) => {
  const { getTranslateAutocomplete = false } = options;
  const speedBumpFeatureconfig = getSpeedBumpFeatureConfig(queryClient).catch((e) => {
    // eslint-disable-next-line no-console
    console.error('Error fetching shopFormSpeedBumpData', e);
  });
  const featureTogglesConfig = serverSideFeatureTogglesQuery(queryClient, {
    names: SINGLE_ROOM_ONLY,
  }).catch((e) => {
    console.error('Error fetching shopFormSingleRoomBrandConfigData', e); // eslint-disable-line no-console
  });

  if (getTranslateAutocomplete)
    serverSideGetTranslateAutocompleteConfigQuery(queryClient).catch((e: Error) => {
      console.error('Error fetching translateAutocompleteData', e); // eslint-disable-line no-console
    });
  return Promise.all([speedBumpFeatureconfig, featureTogglesConfig]);
};
