export type GetGroupOrTransientSubpathProps = {
  groupsPath: string;
  numAttendees: number;
  numRooms: number;
  transientPath: string;
};
/**
 *
 * @param groupPath - path that corresponds to 10+ or group booking (example: group-search, group)
 * @param transientPath - path that corresponds to <10 or transient booking (example: search, reservations)
 * @param numRooms - number of rooms requested (typically for hotel rooms)
 * @param numAttendees - number of attendees requested (typically for meeting room)
 */
export const getGroupOrTransientSubpath = ({
  groupsPath,
  numAttendees,
  numRooms,
  transientPath,
}: GetGroupOrTransientSubpathProps) => {
  return numRooms >= 10 || numAttendees > 0 ? groupsPath : transientPath;
};
