import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export const FeatureTogglesDocument = {
  operationName: 'featureToggles_featureConfigs',
  operationString: `query featureToggles_featureConfigs($names: [String!]!) {
  featureToggles(names: $names) {
    enabled
    name
  }
  featureConfigs(names: $names) {
    name
    config
    conditions
  }
}`,
  originalOpName: 'featureToggles',
};
export function useFeatureTogglesQuery<TData = Types.FeatureTogglesQuery, TError = unknown>(
  variables: Types.FeatureTogglesQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureTogglesQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureTogglesQuery, TError, TData>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}
export function serverSideFeatureTogglesQuery(
  queryClient: QueryClient,
  variables: Types.FeatureTogglesQueryVariables,
  options?: FetchQueryOptions<Types.FeatureTogglesQuery>
) {
  return queryClient.fetchQuery<Types.FeatureTogglesQuery>({
    queryKey: [FeatureTogglesDocument, variables],
    ...options,
  });
}

export const GeocodeDocument = {
  operationName: 'geocode',
  operationString: `query geocode($address: String, $language: String!, $placeId: String, $sessionToken: String) {
  geocode(
    language: $language
    address: $address
    placeId: $placeId
    sessionToken: $sessionToken
  ) {
    match {
      _id
      id
      address {
        _id
        city
        country
        countryName
        postalCode
        state
        stateName
      }
      geometry {
        location {
          latitude
          longitude
        }
        bounds {
          northeast {
            latitude
            longitude
          }
          southwest {
            latitude
            longitude
          }
        }
      }
      name
      placeUri
      type
    }
  }
}`,
  originalOpName: 'geocode',
};
export function useGeocodeQuery<TData = Types.GeocodeQuery, TError = unknown>(
  variables: Types.GeocodeQueryVariables,
  options?: Omit<UseQueryOptions<Types.GeocodeQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GeocodeQuery, TError, TData>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}
export function serverSideGeocodeQuery(
  queryClient: QueryClient,
  variables: Types.GeocodeQueryVariables,
  options?: FetchQueryOptions<Types.GeocodeQuery>
) {
  return queryClient.fetchQuery<Types.GeocodeQuery>({
    queryKey: [GeocodeDocument, variables],
    ...options,
  });
}
