import cx from 'classnames';
import * as React from 'react';
import type { FieldError } from 'react-hook-form';
import { FormErrorIcon } from './form.error-icon';

type FormError = {
  error?: Partial<FieldError>;
} & React.HTMLAttributes<HTMLDivElement>;

/**
 * The standard error message to be used for forms and form inputs
 */
const FormError = React.forwardRef<HTMLDivElement, FormError>(
  ({ error, className, ...rest }, ref) => {
    const messages = (error?.types ? Object.values(error.types) : [error?.message]).filter(Boolean);
    const hasError = messages.length > 0;

    return (
      <div
        ref={ref}
        className={cx(className, {
          hidden: !hasError,
          '!mt-0 block': hasError,
        })}
        {...rest}
      >
        {hasError && (
          <div className="flex flex-col space-y-2">
            {messages.map((message) => (
              <div
                key={String(message)}
                className="text-danger mt-1 flex gap-1 text-sm leading-tight rtl:space-x-reverse"
              >
                <FormErrorIcon />
                <span>{message}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
);

FormError.displayName = 'FormError';

export { FormError };
export default FormError;
