import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export const GetSpeedBumpFeatureConfigsDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs($names: [String!]!) {
  featureConfigs(names: $names) {
    name
    config
    conditions
  }
}`,
  originalOpName: 'getSpeedBumpFeatureConfigs',
};
export function useGetSpeedBumpFeatureConfigsQuery<
  TData = Types.GetSpeedBumpFeatureConfigsQuery,
  TError = unknown
>(
  variables: Types.GetSpeedBumpFeatureConfigsQueryVariables,
  options?: Omit<UseQueryOptions<Types.GetSpeedBumpFeatureConfigsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.GetSpeedBumpFeatureConfigsQuery, TError, TData>({
    queryKey: [GetSpeedBumpFeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideGetSpeedBumpFeatureConfigsQuery(
  queryClient: QueryClient,
  variables: Types.GetSpeedBumpFeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.GetSpeedBumpFeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.GetSpeedBumpFeatureConfigsQuery>({
    queryKey: [GetSpeedBumpFeatureConfigsDocument, variables],
    ...options,
  });
}
