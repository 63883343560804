import * as React from 'react';
import cx from 'classnames';

const InfoCircle: React.FC<{ error: boolean; info: string | null; 'data-testid'?: string }> = ({
  error,
  info,
  ...rest
}) => {
  if (error || info !== null) {
    return (
      <div
        className={cx(
          'text-text-inverse absolute -top-2 inline-block h-5 w-5 rounded-full ltr:-right-2 rtl:-left-2',
          {
            'bg-danger': !!error,
            'bg-primary': !!info && !error,
          }
        )}
        {...(rest?.['data-testid'] && { 'data-testid': `${rest?.['data-testid']}-info` })}
      >
        <span aria-hidden="true">{error ? '!' : info !== null ? info : null}</span>
      </div>
    );
  }

  return null;
};

export type ShopFormButtonProps = {
  label: string;
  ariaLabel: string;
  product: string;
  onClick: () => void;
  hasError: boolean;
  info?: string | null;
  children?: React.ReactNode;
  'data-testid'?: string;
};

export const ShopFormButton = React.forwardRef<HTMLButtonElement, ShopFormButtonProps>(
  ({ product, ariaLabel, children, label, onClick, hasError, info = null, ...props }, ref) => {
    return (
      <div data-osc-product={product} className="flex flex-1">
        <button
          className="shop-form-btn relative w-full"
          ref={ref}
          type="button"
          aria-label={ariaLabel}
          onClick={onClick}
          {...props}
        >
          <span>{label}</span>
          <InfoCircle error={hasError} info={info} data-testid={props?.['data-testid']} />
        </button>
        {children}
      </div>
    );
  }
);

ShopFormButton.displayName = 'ShopFormButton';

export default ShopFormButton;
