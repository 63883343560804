/// <reference types="@types/google.maps" />

import { useState, useEffect } from 'react';

export type FormattedSuggestion = {
  mainText: string;
  secondaryText: string;
};

export type PlacesAutocompleteSuggestion = {
  id: string;
  description: string;
  placeId: string;
  active: boolean;
  index: number;
  formattedSuggestion: FormattedSuggestion;
  matchedSubstrings: google.maps.places.PredictionSubstring[];
  terms: google.maps.places.PredictionTerm[];
  type: string;
  types: string[];
};

export type AutocompletePrediction = google.maps.places.AutocompletePrediction & {
  type: string;
};
type SuggestionsProps = {
  predictions: AutocompletePrediction[] | null;
};

type SuggestionsRenderProps = {
  suggestions: PlacesAutocompleteSuggestion[];
};

type UseSuggestionsHook = (props: SuggestionsProps) => SuggestionsRenderProps;

const formattedSuggestion = (
  formatting: google.maps.places.StructuredFormatting
): FormattedSuggestion => ({
  mainText: formatting.main_text,
  secondaryText: formatting.secondary_text,
});

export const useSuggestions: UseSuggestionsHook = ({ predictions }) => {
  const [suggestions, setSuggestions] = useState<PlacesAutocompleteSuggestion[]>([]);

  useEffect(() => {
    if (!predictions || predictions.length === 0) {
      setSuggestions([]);
      return;
    }
    const results = predictions.map((p, idx) => ({
      id: p.place_id,
      description: p.description,
      placeId: p.place_id,
      active: false,
      index: idx,
      formattedSuggestion: formattedSuggestion(p.structured_formatting),
      matchedSubstrings: p.matched_substrings,
      terms: p.terms,
      type: p.type,
      types: p.types,
    }));
    setSuggestions(results);
  }, [predictions]);

  return { suggestions };
};
