import * as React from 'react';
import { LoginIFrame } from './frame';
import { Dialog } from '@dx-ui/osc-dialog';
import type { GuestLoginConfigOptions, LoginResponse } from './login.types';
import { useTranslation } from 'next-i18next';
import { AccountIcon } from './login.icon';
import cx from 'classnames';
import { sendReward } from '@dx-ui/framework-conductrics';

export type LoginBaseProps = {
  /** Source url for the login page */
  frameSrc: string;
  /** Name of the button to display */
  buttonName?: string;
  /** aria-label of the button */
  buttonLabel?: string;
  /**
   * Set the classNames for the button element
   */
  buttonClassName?: string;
  /** Specify the title for the iframe element */
  title: string;
  /** Display options for the login page */
  options?: GuestLoginConfigOptions;
  /** Provides additional styling to the iframe */
  className?: string;
  /** Provides a parameter for disabling the icon */
  hideIcon?: boolean;
  /** callback to run after login attempt */
  onLoginAttempt: (response: LoginResponse) => Promise<void>;
  /** function to run on open */
  onOpen?: () => void;
};

export type LoginProps = LoginBaseProps & React.HTMLAttributes<HTMLIFrameElement>;

/**
 * A modal that wraps the LoginIFrame component.
 *
 * `import { Login } from '@dx-ui/osc-login'`
 */
export const Login: React.FC<React.PropsWithChildren<LoginBaseProps>> = ({
  options = {},
  className = '',
  title,
  frameSrc,
  buttonName,
  buttonClassName,
  buttonLabel,
  hideIcon,
  onLoginAttempt,
  onOpen,
}) => {
  const [t] = useTranslation('osc-header');
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const open = () => {
    onOpen?.();
    sendReward('nav-sign-in-click');
    setShowDialog(true);
  };
  const close = () => setShowDialog(false);
  const handleLogin = async (response: LoginResponse) => {
    await onLoginAttempt?.(response);
    if (!response.error) {
      close();
    }
  };

  return (
    <>
      <button
        type="button"
        className={cx(buttonClassName, {
          'btn btn-text-text sm:btn-xl flex items-center brand-wa:!font-normal': !buttonClassName,
        })}
        aria-label={buttonLabel || buttonName || t('signIn')}
        onClick={open}
      >
        <span>{buttonName || t('signIn')}</span>
        {!hideIcon ? (
          <span
            aria-hidden
            className="bg-text text-text-inverse ms-2 flex size-5 items-center justify-center rounded-full"
          >
            <AccountIcon className="size-3" />
          </span>
        ) : null}
      </button>
      <Dialog
        ariaLabel={buttonName || t('signIn')}
        isOpen={showDialog}
        onDismiss={close}
        size="md"
        title={title}
      >
        <LoginIFrame
          frameSrc={frameSrc}
          options={options}
          data-e2e="loginIframe"
          onLoginAttempt={handleLogin}
          onClose={close}
          title={title}
          className={className}
        />
      </Dialog>
    </>
  );
};

Login.displayName = 'Login';

export default Login;
